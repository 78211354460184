export const unitTitleMap = {
  'ms g6 u1': 'Mindset & Goals',
  'ms g6 u2': 'Developing a Positive Sense of Self',
  'ms g6 u3': 'Thoughts, Emotions, and Decisions',
  'ms g6 u4': 'Managing Relationships & Social Conflict',
  'ms g6 rbh, opt-in': 'Recognizing Bullying & Harassment',
  'ms g7 u1': 'Mindset & Goals',
  'ms g7 u2': 'Developing a Positive Sense of Self',
  'ms g7 u3': 'Thoughts, Emotions, and Decisions',
  'ms g7 u4': 'Managing Relationships & Social Conflict',
  'ms g7 rbh, opt-in': 'Recognizing Bullying & Harassment',
  'ms g8 u1': 'Mindset & Goals',
  'ms g8 u2': 'Developing a Positive Sense of Self',
  'ms g8 u3': 'Thoughts, Emotions, and Decisions',
  'ms g8 u4': 'Managing Relationships & Social Conflict',
  'ms g8 rbh, opt-in': 'Recognizing Bullying & Harassment',
}

export const lessonTitleMap = {
  'ms g6 u1 l01a-b *choice': {
    number: 1,
    title: 'Starting Middle School/Helping New Students',
  },
  'ms g6 u1 l01a': {
    number: 1,
    title: 'Starting Middle School',
  },
  'ms g6 u1 l01b': {
    number: 1,
    title: 'Helping New Students',
  },
  'ms g6 u1 l02': {
    number: 2,
    title: 'How to Grow Your Brain',
  },
  'ms g6 u1 l03': { number: 3, title: 'Trying New Strategies' },
  'ms g6 u1 l04': { number: 4, title: 'Making Goals Specific' },
  'ms g6 u1 l05': { number: 5, title: 'Breaking Down Your Goals' },
  'ms g6 u1 l06': { number: 6, title: 'Monitoring Your Progress' },
  'ms g6 u1 l07': {
    number: 7,
    title: 'Performance Task: Bringing It All Together',
  },
  'ms g6 u2 l08': { number: 1, title: 'What Are Guiding Principles?' },
  'ms g6 u2 l09': { number: 2, title: 'Your Guiding Principles' },
  'ms g6 u2 l10': { number: 3, title: 'Making Decisions' },
  'ms g6 u2 l11': { number: 4, title: 'Multiple Guiding Principles' },
  'ms g6 u2 l12': { number: 5, title: 'Thinking Short-Term and Long-Term' },
  'ms g6 u2 l13': {
    number: 6,
    title: 'Performance Task: Using Your Guiding Principles',
  },
  'ms g6 u3 l14': { number: 1, title: 'What Emotions Tell You' },
  'ms g6 u3 l15': { number: 2, title: 'Emotions and Your Brain' },
  'ms g6 u3 l16': { number: 3, title: 'How Emotions Affect Your Decisions' },
  'ms g6 u3 l17': { number: 4, title: 'Managing Your Emotions' },
  'ms g6 u3 l18': { number: 5, title: 'What Works Best for You?' },
  'ms g6 u3 l19': {
    number: 6,
    title: 'Performance Task: Raising Awareness About Managing Emotions',
  },
  'ms g6 u4 l20': { number: 1, title: `You're Changing` },
  'ms g6 u4 l21': { number: 2, title: 'Why Conflicts Escalate' },
  'ms g6 u4 l22': { number: 3, title: 'Considering Multiple Perspectives' },
  'ms g6 u4 l23': { number: 4, title: 'Respectful Communication' },
  'ms g6 u4 l24': { number: 5, title: 'Resolving Challenging Conflicts' },
  'ms g6 u4 l25': { number: 6, title: 'Making Amends' },
  'ms g6 u4 l26': { number: 7, title: 'Performance Task: Conflict Solvers' },
  'ms g6 u4 l27': { number: 8, title: 'High School Challenges' },
  'ms g6 rbh l01': { number: 1, title: 'Common Types of Bullying' },
  'ms g6 rbh l02': { number: 2, title: 'Recognizing Bullying' },
  'ms g6 rbh l03': { number: 3, title: 'Responding to Online Bullying' },
  'ms g6 rbh l04': { number: 4, title: 'How to Be an Upstander' },
  'ms g6 rbh l05': { number: 5, title: 'Standing Up and Staying Safe' },
  'ms g6 rbh l06': {
    number: 6,
    title: 'Performance Task: Raising Awareness About Bullying',
  },
  'ms g7 u1 l01a-b *choice': {
    number: 1,
    title: 'Starting Middle School/Helping New Students',
  },
  'ms g7 u1 l01a': {
    number: 1,
    title: 'Starting Middle School',
  },
  'ms g7 u1 l01b': { number: 1, title: 'Helping New Students' },
  'ms g7 u1 l02': { number: 2, title: 'Creating New Pathways in Your Brain' },
  'ms g7 u1 l03': { number: 3, title: 'Learning from Mistakes and Failure' },
  'ms g7 u1 l04': { number: 4, title: 'Identifying Roadblocks' },
  'ms g7 u1 l05': { number: 5, title: 'Overcoming Roadblocks 1' },
  'ms g7 u1 l06': { number: 6, title: 'Overcoming Roadblocks 2' },
  'ms g7 u1 l07': {
    number: 7,
    title: 'Performance Task: Advice on Roadblocks',
  },
  'ms g7 u2 l08': { number: 1, title: 'What is Self-Concept' },
  'ms g7 u2 l09': { number: 2, title: 'Your Self-Concept' },
  'ms g7 u2 l10': { number: 3, title: 'Influences on Self-Concept' },
  'ms g7 u2 l11': { number: 4, title: 'Changes in Self-Concept' },
  'ms g7 u2 l12': { number: 5, title: 'Your Future Self-Concept' },
  'ms g7 u2 l13': {
    number: 6,
    title: 'Performance Task: Who Will You Become?',
  },
  'ms g7 u3 l14': { number: 1, title: 'Emotions Matter' },
  'ms g7 u3 l15': { number: 2, title: 'Feel, Think, Do' },
  'ms g7 u3 l16': { number: 3, title: 'Unhelpful Thoughts' },
  'ms g7 u3 l17': { number: 4, title: 'Reframe the Situation' },
  'ms g7 u3 l18': { number: 5, title: 'Practicing Positive Self-Talk' },
  'ms g7 u3 l19': {
    number: 6,
    title: 'Performance Task: Making Better Decisions',
  },
  'ms g7 u4 l20': { number: 1, title: 'What Makes a Conflict Escalate?' },
  'ms g7 u4 l21': { number: 2, title: 'Keeping Your Cool in a Conflict' },
  'ms g7 u4 l22': { number: 3, title: 'Conflicts and Perspectives' },
  'ms g7 u4 l23': { number: 4, title: 'Resolving Conflict Part 1' },
  'ms g7 u4 l24': { number: 5, title: 'Resolving Conflict Part 2' },
  'ms g7 u4 l25': {
    number: 6,
    title: 'Taking Responsibility for Your Actions',
  },
  'ms g7 u4 l26': {
    number: 7,
    title: 'Performance Task: Tips for Resolving Conflicts',
  },
  'ms g7 rbh l01': { number: 1, title: 'What is Harassment?' },
  'ms g7 rbh l02': { number: 2, title: 'What is Sexual Harassment?' },
  'ms g7 rbh l03': { number: 3, title: 'The Effects of Sexual Harassment' },
  'ms g7 rbh l04': { number: 4, title: 'Gender-Based Harassment' },
  'ms g7 rbh l05': { number: 5, title: 'Our Rights and Responsibilities' },
  'ms g7 rbh l06': {
    number: 6,
    title: 'Performance Task: Preventing Harassment',
  },
  'ms g8 u1 l01': { number: 1, title: 'Welcome!' },
  'ms g8 u1 l02': { number: 2, title: 'Who Am I? My Identity' },
  'ms g8 u1 l03': { number: 3, title: 'My Interests and Strengths' },
  'ms g8 u1 l04': { number: 4, title: 'Harnessing My Strengths' },
  'ms g8 u1 l05': { number: 5, title: 'Pursuing My Interests' },
  'ms g8 u1 l06': { number: 6, title: 'My Future Self' },
  'ms g8 u1 l07': { number: 7, title: 'Performance Task: My Path Forward' },
  'ms g8 u2 l08': { number: 1, title: 'Agency' },
  'ms g8 u2 l09': { number: 2, title: 'Sources of Confidence' },
  'ms g8 u2 l10': { number: 3, title: 'How to Build Confidence 1' },
  'ms g8 u2 l11': { number: 4, title: 'How to Build Confidence 2' },
  'ms g8 u2 l12': { number: 5, title: 'Agency and Confidence' },
  'ms g8 u2 l13': {
    number: 6,
    title: 'Performance Task: Your Confidence-Building Plan',
  },
  'ms g8 u3 l14': { number: 1, title: 'Understanding Stress and Anxiety' },
  'ms g8 u3 l15': { number: 2, title: 'Where Does Stress Come From?' },
  'ms g8 u3 l16': { number: 3, title: 'Can Stress Help You Grow?' },
  'ms g8 u3 l17': { number: 4, title: 'Strategies for Managing Stress' },
  'ms g8 u3 l18': { number: 5, title: 'Changing Strategies and Getting Help' },
  'ms g8 u3 l19': {
    number: 6,
    title: 'Performance Task: My Stress-Management Plan',
  },
  'ms g8 u4 l20': { number: 1, title: 'My Values' },
  'ms g8 u4 l21': { number: 2, title: 'Values and Relationships' },
  'ms g8 u4 l22': { number: 3, title: `Respecting Others' Perspectives` },
  'ms g8 u4 l23': { number: 4, title: 'Finding the Best Solution' },
  'ms g8 u4 l24': { number: 5, title: 'Making Things Right' },
  'ms g8 u4 l25': { number: 6, title: 'Unhealthy Relationships' },
  'ms g8 u4 l26': {
    number: 7,
    title: 'Performance Task: Guide to Healthy Relationships',
  },
  'ms g8 u4 l27': { number: 8, title: 'High School Challenges' },
  'ms g8 rbh l01': { number: 1, title: 'Understanding Bullying' },
  'ms g8 rbh l02': {
    number: 2,
    title: 'Social Factors that Contribute to Bullying',
  },
  'ms g8 rbh l03': {
    number: 3,
    title: 'Environmental Factors that Contribute to Bullying',
  },
  'ms g8 rbh l04': { number: 4, title: 'Speak Up and Start a Movement' },
  'ms g8 rbh l05': { number: 5, title: 'Be Inclusive and Ask for a Change' },
  'ms g8 rbh l06': {
    number: 6,
    title: 'Performance Task: Stand Up for Change!',
  },
}
